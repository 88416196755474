:root {
  --redColor: #d01214;
  --lightGreyColor: #f2f2f2;
  --midGreyColor: #bebebe;
  --middarkGreyColor: #9e9e9e;
  --darkGreyColor: #747474;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --borderColor: #dedede;
  --yellow: #ffce2c;
}

/* small view */
@media (max-width: 767.98px) {
  .mainSlider {
    margin-top: 0 !important;
  }
  .catergorySection .box {
    width: 100% !important;
    text-align: center !important;
    bottom: 10px !important;
    padding: 0 30px !important;
    transform: unset !important;
    top: unset !important;
    left: unset !important;
  }
  .catergorySection .content {
    display: inline-flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
    width: 100% !important;
  }
  .catergorySection h3 {
    font-size: 18px !important;
  }
  .catergorySection .linkto {
    margin: unset !important;
    width: 94px !important;
    height: 25px !important;
    line-height: 25px !important;
  }
  .catergorySection a {
    margin-top: 20px;
    border-radius: 18px;
  }
  .catergorySection img {
    border-radius: 18px;
  }
  .tabSection {
    background-color: var(--lightGreyColor);
    padding-top: 20px;
  }
  .tabSection .active h4 {
    background-color: var(--redColor) !important;
    color: var(--whiteColor) !important;
    border-radius: 5px;
    padding: 5px 20px;
  }
  .tabSection h4 {
    padding: 5px 20px;
  }
  .tabSection .active h4:after {
    content: unset !important;
  }
  .collectionSection .items a {
    margin-right: 10px;
  }
  .collectionSection .titleBar h3,
  .bestsellerSection .titleBar h3,
  .lowStock .titleBar h3 {
    font-size: 16px !important;
  }
  .bestsellerSection .titleBar a,
  .lowStock .titleBar a {
    font-size: 12px !important;
  }
  .bestsellerSection .imgBox {
    height: unset !important;
  }
  .productsList .item {
    max-width: 166px !important;
  }
  .productsList .imgBox {
    max-width: 166px !important;
    height: 166px !important;
  }
  .productsList .content .price {
    display: block !important;
  }
  .productsList .btnWishList img {
    width: 20px !important;
    height: 18px !important;
  }
  .productsList .content h5 {
    font-size: 13px !important;
  }
  .productsList .content .pricenormal,
  .productsList .content .price {
    font-size: 18px !important;
  }
  .productsList .accordion-button:not(.collapsed),
  .productsList .accordion-button:not(.collapsed),
  .productsList .accordion-button {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid var(--midGreyColor) !important;
  }
  .productsList {
    margin-top: 0 !important;
    margin-bottom: 60px!important;
  }
  .detailedProduct h2 {
    font-size: 18px !important;
  }
  .detailedProduct .byMega {
    font-size: 16px !important;
  }
  .detailedProduct .rateAmount {
    font-size: 10px !important;
  }
  .detailedProduct .rateAmount svg {
    width: 11px !important;
    height: 11px !important;
  }
  .detailedProduct .description,
  .detailedProduct .productContent ul li,
  .detailedProduct h5 {
    font-size: 12px !important;
  }
  .detailedProduct h5 small {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .detailedProduct .price {
    font-size: 24px !important;
  }
  .detailedProduct .oldPrice {
    font-size: 16px !important;
  }
  .detailedProduct .discount {
    font-size: 15px !important;
  }
  .detailedProduct .cartBtn,
  .detailedProduct .quantityBox {
    height: 32px !important;
    line-height: 32px !important;
  }
  .detailedProduct .cartBtn svg {
    font-size: 20px !important;
  }
  .serviceSection .items img {
    width: 166px !important;
    border-radius: 20px !important;
  }
  .serviceSection {
    margin-top: 0 !important;
    margin-bottom: 80px;
  }
  .Sdetails {
    margin-top: 0 !important;
    margin-bottom: 75px !important;
  }
  .Profile .infoBox {
    border: 0 !important;
    padding: 0 !important;
  }
  .Profile .infoBox .infoTitle,
  .Profile .infoBox .infoTitle {
    font-size: 16px !important;
    line-height: 32px !important;
    color: var(--blackColor) !important;
  }
  .Profile .inputBox,
  .Profile .inputBoxSelect,
  .Profile .PhoneInputInput {
    font-size: 12px !important;
    height: 32px !important;
  }
  .Sdetails .sideTitlep {
    font-size: 14px !important;
  }
  .Profile .submitBtn {
    padding: 5px 15px !important;
  }
  .Profile .outBtn {
    line-height: 40px !important;
    height: 40px !important;
  }
  .wishlistSection {
    margin-top: 0 !important;
    margin-bottom: 115px !important;
  }
  .wishlistSection .item {
    max-width: 166px !important;
    margin-bottom: 15px;
  }
  .wishlistSection .content {
    border-radius: 0 0 21px 21px;
  }
  .wishlistSection .content h5 {
    font-size: 13px !important;
  }
  .wishlistSection .content .price {
    font-size: 18px !important;
  }
  .btnWishList img {
    width: 20px !important;
    height: 18px;
  }
  .Cart {
    margin-top: 0 !important;
    margin-bottom: 115px !important;
  }
  .Cart .cartImg {
    width: 107px !important;
    height: 107px !important;
  }
  .Cart .itemName {
    font-size: 16px !important;
  }
  .Cart .sale {
    font-size: 16px !important;
  }
  .Cart .infoBox {
    border: 0 !important;
    padding: 0 !important;
  }
  .Cart .shadowBoxMobile {
    box-shadow: 2px 5px 10px var(--midGreyColor);
    border-radius: 18px !important;
    margin-bottom: 20px !important;
    padding: 15px 20px !important;
  }
  .Cart .deleteBtn {
    top: 10px !important;
  }
  .Cart .sideTitlep {
    font-size: 14px !important;
  }
  .Cart .proceedBtn,
  .Cart .continueBtn {
    height: 41px !important;
    line-height: 41px !important;
    font-size: 12px !important;
  }
  .Cart .topMargin {
    margin-top: 0 !important;
  }
  .Checkout {
    margin-top: 0 !important;
    margin-bottom: 50px !important;
  }
  .Profile {
    margin-top: 0px !important;
    padding-bottom: 60px;
  }
  .Profile .editBtn,
  .Profile .outBtn {
    height: 41px !important;
    line-height: 41px !important;
    font-size: 12px !important;
  }
  .Profile .editBtn {
    margin-top: 0 !important;
  }
  .Profile .infoBox .info {
    border: 1px solid #f5f5f5 !important;
    border-radius: 21px !important;
    width: 100% !important;
    padding: 10px !important;
    font-size: 12px !important;
    font-family: "Lato" !important;
    color: var(--middarkGreyColor) !important;
    font-weight: bold !important;
  }
  .Profile .addresses{
    padding: 0;
  }
  .Profile .addresses li{
    background-color:#F5F5F5!important;
    border-radius: 21px!important;
    padding: 5px 15px !important;
  }

  .Deals{
    margin-bottom: 100px!important;
  }
  .Deals .item{
    max-width: 317px!important;
    margin: auto;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .desktopHeader .navbar {
    width: 60% !important;
  }
  header .container {
    max-width: 100% !important;
  }
  header .socialMedia img {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .infoBar .item {
    display: flex !important;
  }
  .catergorySection h3 {
    font-size: 45px !important;
  }
  .catergorySection .linkto {
    height: 25px !important;
    line-height: 25px !important;
  }
  .navbar-collapse {
    background-color: var(--whiteColor);
    margin-top: 10px;
    padding: 0 20px;
    box-shadow: 2px 5px 10px var(--midGreyColor);
  }
}
