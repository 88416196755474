:root {
  --redColor: #d01214;
  --lightGreyColor: #f2f2f2;
  --midGreyColor: #bebebe;
  --middarkGreyColor: #9e9e9e;
  --darkGreyColor: #747474;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --borderColor: #dedede;
  --yellow: #ffce2c;
}

.mobileHeader {
  padding: 20px 30px;
}

.mobileHeader .title {
  font-size: 24px;
  font-weight: bold;
  color: var(--blackColor);
}

.mobileHeader .bell {
  font-size: 25px;
  color: var(--blackColor);
}

.mobileHeader .dot {
  position: absolute;
  bottom: -8px;
  right: 13px;
  font-size: 55px;
  color: var(--redColor);
}

.mobileHeader .inputBox {
  font-size: 13px;
  background-color: var(--lightGreyColor);
  color: var(--darkGreyColor);
  height: 52px;
  border: 0;
  border-radius: 7px;
  width: 100%;
  padding-left: 40px;
}

.mobileHeader .inputBox:focus-visible {
  border: 2px solid var(--redColor);
  outline: none;
}

.mobileHeader .search {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--darkGreyColor);
  font-size: 15px;
}

.mobileHeader .searchBtn {
  height: 52px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  background-color: var(--redColor);
  color: var(--whiteColor);
}

.mobileFooter {
  background-color: var(--lightGreyColor);
  padding: 30px;
  box-shadow: 2px 2px 20px var(--midGreyColor);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.mobileFooter svg {
  font-size: 20px;
}

.mobileFooter .active {
  position: relative;
}

.mobileFooter .active svg {
  color: var(--redColor);
}
.mobileFooter .active:after {
  background-image: url("../images/activeDot.png");
  background-size: cover;
  bottom: -15px;
  content: "";
  height: 6px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 6px;
}

.mobileNewsLetterSection {
  background-color: var(--redColor);
}

.mobileNewsLetterSection .row {
  margin: auto;
  height: 111px;
  display: flex;
  align-items: center;
}

.mobileNewsLetterSection img {
  width: 31px;
  height: 21px;
}

.mobileNewsLetterSection span {
  font-size: 12px;
  color: var(--whiteColor);
  font-weight: bold;
  padding-left: 10px;
}

.mobileNewsLetterSection .form input {
  width: 60%;
  border: 1px solid var(--darkGreyColor);
  background-color: var(--whiteColor);
  border-top-left-radius: 29px;
  border-bottom-left-radius: 29px;
  font-size: 10px;
  color: var(--midGreyColor);
  height: 27px;
  padding-left: 10px;
}

.mobileNewsLetterSection .form button {
  width: 40%;
  border: 0;
  background-color: var(--darkGreyColor);
  border-top-right-radius: 29px;
  border-bottom-right-radius: 29px;
  font-size: 10px;
  color: var(--whiteColor);
  height: 27px;
}

.CategoryList {
  padding: 0 30px;
}

.CategoryList .name {
  font-size: 16px;
  font-weight: 500;
  color: var(--blackColor);
}

.CategoryList span {
  font-size: 20px;
  font-weight: 500;
  color: var(--blackColor);
  float: right;
  margin: auto;
}

.CategoryList .One {
  padding: 20px 15px;
  border-bottom: 2px solid var(--midGreyColor);
}

.mobileFilters .Filtername {
  font-size: 13px;
  color: var(--darkGreyColor);
}

.mobileFilters .listByMobile svg {
  font-size: 13px;
  color: var(--darkGreyColor);
  margin-left: 5px;
}

.mobileFilters .listByMobile .red svg {
  color: var(--redColor);
}

.productsList .filterAsideBox {
  position: absolute;
  top: 30px;
  left: -100%;
  background-color: white;
  width: 90%;
  height: 100%;
  padding: 15px;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  box-shadow: 5px -5px 5px rgb(0 0 0 / 10%);
}

.productsList .filterAsideBoxShow {
  left: 0;
}

.productsList .closeFilter {
  border: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  color: var(--redColor);
}

.productsList .closeFilter svg {
  color: var(--blackColor);
  font-size: 25px;
}

.productsList .filterAsideBox ul {
  padding: 0;
}
.productsList .filterAsideBox ul li {
  list-style: none;
  border-bottom: 1px solid var(--midGreyColor);
  padding: 10px 0;
}
.productsList .filterAsideBox .accordion-body {
  padding-top: 0 !important;
}

.sortListBox {
  background-color: white;
  padding: 10px 20px;
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  box-shadow: 0px -15px 10px -15px rgb(0 0 0 / 30%);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.sortListBoxShow {
  bottom: 80px;
}

.closeSort {
  border: 0;
  background-color: transparent;
}

.closeSort svg {
  color: var(--whiteColor);
  font-size: 25px;
  border-radius: 50%;
  background-color: var(--redColor);
  padding: 5px;
}

.sortTitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--redColor);
}
.sortListBox ul {
  padding: 0;
  margin-top: 20px;
}

.sortListBox ul li {
  list-style: none;
  font-size: 14px;
  color: var(--blackColor);
  padding-bottom: 15px;
  border-bottom: 1px solid var(--midGreyColor);
}

.Profile .mobileDp{
  width: 138px;
  height: 138px;
  border: 1px solid var(--redColor);
  padding: 10px;
  border-radius: 50%;
}

.Profile .nameMobile{
  font-size: 16px;
  font-weight: 800;
  color: var(--redColor);
  margin-top: 15px;
}

.Profile .tabMobile{
  font-size: 13px;
  color: var(--darkGreyColor);
}

.Profile .tabMobileActive{
  font-size: 13px;
  color: var(--whiteColor);
  background-color: var(--redColor);
  border-radius: 5px;
  padding: 3px;
}

.Profile .mobileTabsBorder{
  padding-bottom: 10px;
  border-bottom: 1px solid var(--midGreyColor);
}

.Profile .mobileinfoValue{
  border: 1px solid #F5F5F5;
  border-radius: 21px;
  width: 100%;
  padding: 10px;
  font-size: 12px;
  font-family: 'Lato';
  color: var(--middarkGreyColor);
  font-weight: bold;
}

.Profile .settingsIcon{
  position: absolute;
  bottom: 55px;
  right: 35%;
  transform: translateX(50%);
  color: var(--redColor);
  font-size: 25px;
  background-color: white;
  box-shadow: 0px 3px 10px var(--midGreyColor);
  border-radius: 50%;
  padding: 5px;
}

.Profile .activeSettings{
  background-color: var(--blackColor)!important;
  color: var(--whiteColor)!important;
}

.Profile .settingsIcons{
  width: 20px;
  height: 23px;
  color: var(--redColor);
}

.Profile .settingsTitle{
  font-size: 16px;
  font-weight: 500;
  color: var(--blackColor);
  margin-left: 5px;
}

.Profile .rightArrowSettings{
  font-size: 20px;
  font-weight: 500;
  color: var(--blackColor);
  float: right;
  margin: auto;
}
.choose-selection-button{
  font-size: 12px;
  margin-bottom: 20px;
}
.productsList .content h5 {

  max-height: 60px;
}