:root {
  --redColor: #d01214;
  --lightGreyColor: #f2f2f2;
  --midGreyColor: #bebebe;
  --middarkGreyColor: #9e9e9e;
  --darkGreyColor: #747474;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --borderColor: #dedede;
  --yellow: #ffce2c;
}

body,
a,
p,
h3,
h5 {
  font-family: "Roboto", sans-serif;
}
a,
a:hover {
  text-decoration: none;
  color: var(--blackColor);
}
img {
  width: 100%;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid var(--redColor); /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}
.spinner-search {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.search-spinner {
  width: 20px;
  height: 20px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid var(--redColor); /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.pointer {
  cursor: pointer;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-120{
  margin-bottom: 120px;
}
.mb-165 {
  margin-bottom: 165px;
}
.mt-20{
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30{
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-80 {
  margin-top: 80px;
}
.text-right {
  text-align: right;
}
button {
  transition: 0.3s;
}
button:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--redColor) !important;
}

.red {
  color: var(--redColor) !important;
}

.btnWishList {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 0;
}

.btnWishList img {
  width: 50px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100% !important;
  }
}
.desktopHeader {
  max-height: 100px;
  position: relative;
  z-index: 99;
  padding-top: 25px;
}
.desktopHeader .navbar {
  height: 100px;
}
.desktopHeader .navbar a {
  color: var(--darkGreyColor);
  font-size: 16px;
  margin-right: 28px;
}
.desktopHeader .socialMedia {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  height: 100px;
  align-items: center;
  padding: 0;
}
.desktopHeader .socialMedia li {
  margin-right: 30px;
}
/* header .socialMedia li:last-child{margin-right: 0;} */
.mainSlider {
  margin-top: -100px;
}
.infoBar {
  margin-top: 43px;
  margin-bottom: 43px;
}
.infoBar .item {
  width: fit-content;
  margin: 0 auto;
}
.infoBar .icon {
  width: 43px;
  margin-right: 16px;
  float: left;
  align-items: center;
  display: flex;
  height: 48px;
}
.infoBar .text {
  display: inline-block;
}
.infoBar .text p {
  font-weight: bold;
}
.infoBar .text h5 {
  margin-bottom: 0;
  color: var(--darkGreyColor);
  font-weight: bold;
}
.infoBar .text p {
  font-size: 16px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.infoBar .middleBar {
  border-right: 1px solid var(--midGreyColor);
  border-left: 1px solid var(--midGreyColor);
}

.catergorySection a {
  display: block;
  box-shadow: 2px 5px 10px var(--midGreyColor);
  text-decoration: none;
  position: relative;
}
.catergorySection .box {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
.catergorySection h3 {
  font-size: 69px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 5px var(--midGreyColor);
}

.catergorySection .linkto {
  background-color: var(--redColor);
  border-radius: 30px;
  width: 132px;
  height: 31px;
  display: block;
  text-align: center;
  line-height: 31px;
  color: var(--whiteColor);
  font-size: 10px;
  margin: 0 auto;
  box-shadow: none;
}
.collectionSection .categoryScroll{
  width: 100%;
  overflow: auto;
}
.tabSection {
  margin-top: 53px;
}

.tabSection .row:first-child {
  border-bottom: 2px solid var(--midGreyColor);
}
.tabSection .active h4 {
  color: var(--redColor);
  position: relative;
}
.tabSection .active h4:after {
  content: "";
  background-color: var(--redColor);
  width: calc(100% - 20px);
  height: 5px;
  left: 20px;
  bottom: -11px;
  position: absolute;
  border-radius: 30px;
}

.tabSection h4 {
  color: var(--darkGreyColor);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.productsSlider .item{
  max-width: 287px;
  position: relative;
  margin: auto;
}

.tabSection .productsSlider {
  padding-bottom: 76px;
  padding-top: 15px;
}
.tabSection .productsSlider .imgBox{
  max-width: 287px;
  height: 287px;
  overflow: hidden;
}
.tabSection .productsSlider .content {
  padding: 15px;
  box-shadow: 3px 12px 12px #ccc;
  border-radius: 0 0 36px 36px;
}
.tabSection .productsSlider .content .price {
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  line-height: 37px;
  justify-content: space-between;
}
.tabSection .productsSlider .content .pricenormal {
  /* text-align: right; */
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  line-height: 37px;
}
.tabSection .productsSlider .content .price .off{
  font-size: 13px;
  font-weight: 300;
  color: var(--redColor);
  font-family: 'Roboto';
}
.tabSection .productsSlider .content .price .was{
  font-size: 9px;
  font-weight: 400;
  color: var(--darkGreyColor);
  font-family: 'Roboto';
}
.tabSection .productsSlider .content .sub {
  font-size: 12px;
}
.tabSection .productsSlider .content h5 {
  font-size: 16px;
  color: var(--redColor);
  min-height: 39px;
  max-height: 39px;
  overflow: hidden;
}
.tabSection .productsSlider .content .price button, .tabSection .productsSlider .content .pricenormal button  {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.collectionSection .categoryScroll{
  width: 100%;
  overflow: auto;
  display: inline-flex;
  margin-bottom: 20px;
}

.collectionSection .titleBar {
  margin-top: 64px;
  margin-bottom: 75px;
}
.collectionSection .titleBar h3 {
  text-transform: uppercase;
  font-size: 35px;
  font-weight: bold;
}
.collectionSection .titleBar h3 span {
  color: var(--redColor);
}
.collectionSection .titleBar a {
  text-decoration: none;
  color: var(--darkGreyColor);
  font-size: 22px;
}
.collectionSection .titleBar a span img {
  width: 22px;
  margin-left: 5px;
}
.collectionSection .items a {
  text-decoration: none;
  color: var(--blackColor);
}
.collectionSection .items img {
  border-radius: 85px;
  border: 1px solid var(--redColor);
  width: 292px;
  margin: 0 auto;
}

.collectionSection .items p {
  width: 180px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 36px;
}
.bestsellerSection {
  background-color: var(--lightGreyColor);
}
.bestsellerSection .titleBar {
  padding-top: 74px;
  padding-bottom: 45px;
}
.bestsellerSection .titleBar h3 {
  text-transform: uppercase;
  font-size: 35px;
  font-weight: bold;
}
.bestsellerSection .titleBar h3 span {
  color: var(--redColor);
}
.bestsellerSection .titleBar a {
  text-decoration: none;
  color: var(--darkGreyColor);
  font-size: 22px;
}
.bestsellerSection .titleBar a span img {
  width: 22px;
  margin-left: 5px;
}

.newsLetterSection {
  height: 130px;
  background-color: var(--redColor);
}

.newsLetterSection .row {
  height: 130px;
}

.newsLetterSection img {
  width: 65px;
  float: left;
}
.newsLetterSection span {
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: var(--whiteColor);
  margin-left: 38px;
  letter-spacing: 5px;
}
.newsLetterSection .form input {
  width: 60%;
  height: 54px;
  padding-left: 25px;
  border-radius: 30px 0 0 30px;
  border: 0;
  float: left;
}
.newsLetterSection .form button {
  width: 40%;
  border-radius: 0 30px 30px 0;
  border: 0;
  height: 54px;
  background-color: var(--darkGreyColor);
  color: var(--whiteColor);
  font-size: 20px;
}

.bestsellerSection .item {
  max-width: 219px;
  margin: 0 auto;
}
.bestsellerSection .content {
  padding: 15px;
  box-shadow: 3px 12px 12px #ccc;
  border-radius: 0 0 36px 36px;
  background-color: var(--whiteColor);
  margin-bottom: 25px;
}
.bestsellerSection .imgBox {
  max-width: 219px;
  height: 219px;
  overflow: hidden;
}

.bestsellerSection .content .price {
  text-align: right;
  font-size: 21px;
  font-weight: bold;
  display: flex;
  line-height: 37px;
  justify-content: space-between;
}
.bestsellerSection .content .pricenormal {
  /* text-align: right; */
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  /*line-height: 37px;*/
}
.bestsellerSection .content .price .off{
  font-size: 13px;
  font-weight: 300;
  color: var(--redColor);
  font-family: 'Roboto';
}
.bestsellerSection .content .price .was{
  font-size: 9px;
  font-weight: 400;
  color: var(--darkGreyColor);
  font-family: 'Roboto';
}
.bestsellerSection .content .sub {
  font-size: 7px;
}
.bestsellerSection .content h5 {
  font-size: 11px;
  color: var(--redColor);
  min-height: 30px;
  max-height: 30px;
  overflow: hidden;
}
.bestsellerSection .content h3 {
  font-size: 22px;
  font-weight: bold;
  color: var(--redColor);
}
.bestsellerSection .content small {
  margin-left: 15px;
  font-size: 15px;
  font-weight: 400;
  color: var(--middarkGreyColor);
}
.bestsellerSection .content .price button, .bestsellerSection .content .pricenormal button {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.lowStock .titleBar {
  padding-top: 74px;
  padding-bottom: 45px;
}
.lowStock .titleBar h3 {
  text-transform: uppercase;
  font-size: 35px;
  font-weight: bold;
}
.lowStock .titleBar h3 span {
  color: var(--redColor);
}
.lowStock .titleBar a {
  text-decoration: none;
  color: var(--darkGreyColor);
  font-size: 22px;
}
.lowStock .titleBar a span img {
  width: 22px;
  margin-left: 5px;
}

.desktopFooter {
  padding-top: 34px;
}
.desktopFooter .logo {
  width: 84px;
}
.desktopFooter .aboutTitle {
  font-weight: bold;
  color: var(--darkGreyColor);
  margin-left: 25px;
  font-size: 20px;
}

.desktopFooter p {
  font-size: 18px;
  color: var(--darkGreyColor);
  margin-top: 15px;
}
.desktopFooter h3 {
  font-size: 20px;
  color: var(--darkGreyColor);
  font-weight: bold;
  line-height: 96px;
  /* margin-top: 45px;
  margin-bottom: 45px; */
}

.desktopFooter .info li{
  list-style: none;
  margin-top: 10px;
  margin-bottom: 15px;
}

.desktopFooter .info li a{
  font-size: 18px;
  color: var(--darkGreyColor);
  list-style: none;
}

.desktopFooter ul {
  padding: 0;
}
.desktopFooter .logos ul li {
  display: inline;
  margin-right: 10px;
  list-style: none;
}
.desktopFooter .logos ul li svg {
  color: var(--darkGreyColor);
  font-size: 25px;
}

.desktopFooter .bottomText{
  font-size: 20px;
  color: var(--redColor);
  margin: 60px 0;
}

.bread-crumb {
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 0;
}
.bread-crumb li {
  font-size: 16px;
  margin-right: 3px;
  color: #747474;
  font-weight: 500;
}
.bread-crumb li a {
  font-size: 16px;
  margin-right: 3px;
  color: #747474;
  font-weight: 500;
}

.authenticationCard {
  border-radius: 32px;
  box-shadow: 0 0 5px #747474;
  padding: 15px;
  padding-top: 36px;
  margin-top: 49px;
  margin-bottom: 88px;
}
.authenticationCard .topBar {
  border-bottom: 1px solid #bebebe;
  margin-bottom: 62px;
}

.authenticationCard .topBar button {
  background-color: transparent;
  border: 0;
  width: 100%;
  font-weight: bold;
  color: var(--redColor);
  margin-bottom: 12px;
  position: relative;
}

.authenticationCard .topBar button.active:after {
  content: "";
  height: 6px;
  width: 90%;
  background-color: var(--redColor);
  border-radius: 15px;
  display: block;
  bottom: -15px;
  position: absolute;
  left: 5%;
}

.authenticationCard label {
  font-weight: 500;
  color: #747474;
  font-size: 16px;
  line-height: 36px;
}
.authenticationCard input {
  background-color: #f5f5f5;
  border: 0;
  border-radius: 21px;
  height: 41px;
  font-size: 13px;
}

.authenticationCard .submit button {
  background-color: transparent;
  border: 0;
  width: 50%;
  background-color: var(--redColor);
  color: white;
  border-radius: 21px;
  height: 41px;
  font-size: 16px;
}

.authenticationCard .submit a {
  font-family: "Lato";
  font-size: 13px;
  color: var(--middarkGreyColor);
  margin-top: 10px;
}

.authenticationCard .showPass {
  position: relative;
}

.authenticationCard .showPass svg {
  position: absolute;
  right: 25px;
  top: 11px;
  color: var(--darkGreyColor);
  font-size: 15px;
  cursor: pointer;
}

.serviceSection{
  margin-top: 60px;
}

.serviceSection h1 {
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
  margin-bottom: 76px;
}
.serviceSection .titleBar {
  margin-top: 64px;
  margin-bottom: 75px;
}
.serviceSection .titleBar h3 {
  text-transform: uppercase;
  font-size: 59px;
  font-weight: bold;
}
.serviceSection .titleBar h3 span {
  color: var(--redColor);
}
.serviceSection .titleBar a {
  text-decoration: none;
  color: var(--darkGreyColor);
  font-size: 22px;
}
.serviceSection .titleBar a span img {
  width: 22px;
  margin-left: 5px;
}
.serviceSection .item {
  margin-bottom: 20px;
}
.serviceSection .items a {
  text-decoration: none;
  color: var(--blackColor);
}
.serviceSection .items img {
  border-radius: 85px;
  border: 1px solid var(--redColor);
  width: 292px;
  margin: 0 auto;
}

.serviceSection .items p {
  width: 180px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 36px;
}

.wishlistSection {
  margin-top: 60px;
  margin-bottom: 165px;
}

.wishlistSection h1 {
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
  margin-bottom: 76px;
}
.wishlistSection .item {
  position: relative;
}
.wishlistSection .content {
  padding: 15px;
  box-shadow: 3px 12px 12px #ccc;
  border-radius: 0 0 80px 80px;
}
.wishlistSection .content .price {
  text-align: right;
  font-size: 28px;
  font-weight: bold;
}
.wishlistSection .content .sub {
  font-size: 12px;
}
.wishlistSection .content h5 {
  font-size: 16px;
  color: var(--redColor);
}
.wishlistSection .content .price button {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.productsList {
  margin-top: 110px;
  margin-bottom: 165px;
}
.productsList h1 {
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
  margin-bottom: 76px;
}
.productsList .item {
  max-width: 287px;
  position: relative;
  margin-bottom: 20px;
}
.productsList .imgBox{
  max-width: 287px;
  height: 287px;
  overflow: hidden;
}
.productsList  .content {
  padding: 15px;
  box-shadow: 3px 12px 12px #ccc;
  border-radius: 0 0 36px 36px;
}
.productsList  .content .price {
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  line-height: 37px;
  justify-content: space-between;
}
.productsList  .content .pricenormal {
  /*text-align: right;*/
  font-size: 28px;
  font-weight: bold;
  line-height: 37px;

  text-align: center;
}
.productsList  .content .price .off{
  font-size: 13px;
  font-weight: 300;
  color: var(--redColor);
  font-family: 'Roboto';
}
.productsList  .content .price .was{
  font-size: 9px;
  font-weight: 400;
  color: var(--darkGreyColor);
  font-family: 'Roboto';
}
.productsList .content .sub {
  font-size: 12px;
  color: #707070;
}
.productsList .content h5 {
  font-size: 16px;
  color: var(--redColor);
  min-height: 45px;
  max-height: 45px;
  overflow: hidden
}
.productsList .content .price button, .productsList .content .pricenormal button{
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.ViewVariationsButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productsList .btnWishList {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 0;
}
.productsList .btnWishList img {
  width: 50px;
}
.productsList .filterBox .filterMainButton {
  background-color: white;
  border-radius: 21px;
  width: 100%;
  border: 0;
  color: var(--redColor);
  font-size: 14px;
  height: 31px;
  box-shadow: 0px 3px 10px var(--midGreyColor);
  margin-bottom: 21px;
}
.productsList .filterBox .filterMainButton.active {
  background-color: var(--redColor);
  color: white;
}
.productsList .filterBox ul {
  list-style: none;
  margin-bottom: 21px;
}
.productsList .filterBox ul li {
  color: var(--darkGreyColor);
  font-size: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.productsList .filterBox label{
  cursor: pointer;
  text-transform: uppercase;
}

.detailedProduct {
  margin-bottom: 165px;
}
.detailedProduct .bread-crumb {
  margin-bottom: 33px;
}
.detailedProduct .mainProductImageBox {
  border: 1px solid var(--midGreyColor);
  margin-bottom: 15px;
}
.detailedProduct h2 {
  font-size: 32px;
  color: var(--redColor);
  font-weight: 700;
  margin: 0;
}

.detailedProduct .description {
  font-size: 18px;
  color: var(--darkGreyColor);
  margin: 0;
}

.detailedProduct .productThumbBox {
  border: 1px solid var(--lightGreyColor);
}
.detailedProduct h5 {
  font-size: 16px;
  font-weight: 500;
}
.detailedProduct h5 small {
  font-weight: 400;
}
.detailedProduct .productContent ul {
  padding: 0;
  list-style: none;
}
.detailedProduct .productContent ul li {
  float: left;
  border: 1px solid #000;
  padding: 5px 15px;
  border-radius: 21px;
  margin-right: 18px;
  font-size: 16px;
  margin-bottom: 10px;
}
.detailedProduct .productContent ul li.selected {
  background-color: black;
  color: white;
}
.detailedProduct .productContent .acitve-border{
  background-color: white !important;
  color: black !important;
  border: 2px solid var(--redColor) !important;
  border-radius: 0px !important;
  padding: 5px !important;
}
.detailedProduct .productContent .acitve-border p{
  margin-bottom: 0px !important;
}
.detailedProduct .productContent .unactive-border{
  background-color: white !important;
  color: black !important;
  border: none !important;
  
}

.detailedProduct .byMega {
  font-size: 22px;
  color: var(--blackColor);
}

.detailedProduct .rateAmount svg {
  width: 22px;
  height: 22px;
}

.detailedProduct .rateAmount {
  font-size: 16px;
  color: var(--darkGreyColor);
  line-height: 22px;
}

.detailedProduct .oldPrice {
  font-size: 16px;
  font-weight: 500;
  color: var(--darkGreyColor);
}

.detailedProduct .oldPrice span {
  font-weight: 400 !important;
}

.detailedProduct .discount {
  font-size: 24px;
  font-weight: 300;
  color: var(--redColor);
  margin-left: 15px;
}

.detailedProduct .price {
  font-size: 40px;
  font-weight: bold;
  color: var(--blackColor);
}

.detailedProduct .cartBtn {
  background-color: var(--redColor);
  border-radius: 26px;
  border: 1px solid var(--darkGreyColor);
  color: var(--whiteColor);
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  line-height: 52px;
  height: 52px;
  padding: 0 17px;
}

.detailedProduct .cartBtn svg {
  background-color: var(--whiteColor);
  color: var(--redColor);
  border-radius: 50%;
  font-size: 30px;
}

.detailedProduct .quantity {
  font-size: 21px;
  font-weight: 500;
  color: var(--darkGreyColor);
}

.detailedProduct .quantityBox {
  width: 45px;
  height: 52px;
  border: 0;
  font-size: 18px;
  color: var(--middarkGreyColor);
}

.detailedProduct .quantityBox:focus {
  outline: none;
}

.detailedProduct .quantityBox::-webkit-inner-spin-button,
.detailedProduct .quantityBox::-webkit-outer-spin-button {
  opacity: 1;
}

.Profile {
  margin-top: 110px;
  margin-bottom: 50px;
}

.Profile .topTitle {
  font-size: 59px;
  font-weight: bold;
  color: var(--redColor);
  text-transform: uppercase;
}

.Profile .dp {
  width: 114px;
  height: 114px;
  border: 1px solid var(--redColor);
  padding: 5px;
  border-radius: 50%;
}

.Profile .infoBox {
  border: 1px solid var(--borderColor);
  padding: 30px 25px;
  margin-top: 32px;
}

.Profile .infoBox .title {
  font-size: 15px;
  font-weight: bold;
  color: var(--blackColor);
  margin-bottom: 50px;
}

.Profile .infoBox .infoTitle {
  font-size: 17px;
  font-weight: 500;
  color: var(--darkGreyColor);
  margin-bottom: 30px;
  line-height: 41px;
}

.Profile .infoBox .info {
  font-family: "Lato";
  font-size: 13px;
  font-weight: 400;
  color: var(--middarkGreyColor);
}

.Profile .infoBox .sideTitle {
  font-size: 15px;
  font-weight: 400;
  color: var(--blackColor);
  margin-bottom: 25px !important;
  margin: auto;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--midGreyColor);
  width: 100%;
  cursor: pointer;
}

.Profile .infoBox .sideTitleActive {
  font-size: 15px;
  font-weight: bold;
  color: var(--redColor);
  margin-bottom: 25px !important;
  margin: auto;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--midGreyColor);
  width: 100%;
  position: relative;
  cursor: pointer;
}

.Profile .infoBox .sideTitleActive:before {
  content: "";
  position: absolute;
  left: -45px;
  top: -34px;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 20px solid var(--borderColor);
}
.Profile .infoBox .sideTitleActive:after {
  content: "";
  position: absolute;
  left: -41px;
  top: -34px;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 17px solid var(--whiteColor);
}

.Profile .infoBox .sideNoBorder {
  border: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.Profile .editBtn {
  width: 100%;
  background-color: var(--blackColor);
  color: var(--whiteColor);
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 26px;
  height: 52px;
  line-height: 52px;
  margin-top: 36px;
  border: 0;
}

.Profile .activeEdit {
  background-color: var(--whiteColor) !important;
  color: var(--redColor) !important;
  border:1px solid var(--blackColor) !important;
}

.Profile .outBtn {
  width: 100%;
  background-color: var(--redColor);
  color: var(--whiteColor);
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 26px;
  height: 52px;
  line-height: 52px;
  margin-top: 36px;
  border: 0;
}

.Profile .inputBox {
  border: 0;
  height: 41px;
  border-radius: 21px;
  padding-left: 20px;
  font-size: 13px;
  font-family: "Lato";
  width: 100%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
}

.Profile .PhoneInputInput{
  border: 0;
  height: 41px;
  border-radius: 21px;
  padding-left: 20px;
  font-size: 13px;
  font-family: "Lato";
  width: 100%;
  margin-bottom: 20px;
  margin: 0;
  background-color: transparent;
}

.Profile .textareaBox {
  order: 0;
  border-radius: 21px;
  padding-left: 20px;
  font-size: 13px;
  font-family: "Lato";
  width: 100%;
  border: 0;
  resize: none;
  margin-bottom: 20px;
  background-color: #f5f5f5;
}

.Profile .inputBoxSelect {
  border: 0;
  height: 41px;
  border-radius: 21px;
  padding-left: 20px;
  font-size: 13px;
  font-family: "Lato";
  width: 100%;
  margin-bottom: 20px;
  color: var(--middarkGreyColor);
  background-color: #f5f5f5;
}

.Profile .infoBox .checkLabel {
  font-family: "Lato";
  font-size: 13px;
  font-weight: 400;
  color: var(--middarkGreyColor);
  margin-bottom: 20px;
}

.Profile .inputBox::placeholder,
.Profile .PhoneInputInput::placeholder,
.Profile .textareaBox::placeholder {
  font-size: 13px;
  color: var(--middarkGreyColor);
}

.Profile .inputBox:focus,
.Profile .PhoneInputInput:focus,
.Profile .inputBoxSelect:focus,
.Profile .textareaBox:focus {
  border: 2px solid var(--redColor);
  outline: none;
}

.Profile .PhoneInputCountry {
  border-right: 1px solid var(--borderColor);
  padding-right: 10px;
  /* margin-bottom: 20px; */
}

.Profile .inputCheckbox {
  outline: none;
  width: 13px;
  height: 13px;
  border: 1px solid var(--borderColor);
  background-color: var(--whiteColor);
  appearance: none;
  border-radius: 50%;
  cursor: pointer;
}

.Profile .inputCheckbox:checked {
  outline: none;
  appearance: none;
  width: 13px;
  height: 13px;
  border: 3px double var(--borderColor);
  background-color: var(--redColor);
  cursor: pointer;
}

.Profile .orderInfos {
  font-size: 17px;
  font-weight: 500;
  color: var(--darkGreyColor);
}

.Profile .orderInfo {
  font-family: "Lato";
  font-size: 13px;
  font-weight: 400;
  color: var(--middarkGreyColor);
  margin-right: 20px;
}

.Profile .orderBtns {
  font-size: 12px;
  font-weight: 400;
  color: var(--redColor);
  margin-right: 15px;
  text-decoration: underline;
  border: 0;
  background-color: transparent;
}

.Profile .rates li {
  display: inline;
  margin-right: 5px;
  color: var(--yellow);
}

.Profile .infoBox .subInfo {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  color: var(--midGreyColor);
}

.Profile .rateInput svg {
  width: 20px;
  height: 20px;
}

.Profile .addresses li {
  font-family: "Lato";
  font-size: 13px;
  font-weight: 400;
  color: var(--middarkGreyColor);
  display: inline;
  margin-right: 15px;
  padding: 15px 35px;
  border-radius: 21px;
  cursor: pointer;
}

/* .Profile .addresses li:hover {
  background-color: var(--lightGreyColor);
  color: var(--redColor);
} */

.Profile .submitBtn {
  border: 0;
  border-radius: 21px;
  background-color: var(--redColor);
  color: var(--whiteColor);
  padding: 10px 35px;
}

.Sdetails{
  margin-top: 110px;
}

.Sdetails .mainTitle {
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
}

.Sdetails .price {
  font-size: 16px;
  color: var(--darkGreyColor);
}

.Sdetails .sideTitlep {
  font-size: 18px;
  color: var(--blackColor);
  margin-bottom: 20px;
}

.Cart{
  margin-top: 110px;
  margin-bottom: 165px;
}

.Cart .topMargin{
  margin-top: 80px;
}

.Cart h1 {
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
  margin-bottom: 76px;
}

.Cart .infoBox {
  border: 1px solid var(--borderColor);
  padding: 30px 25px;
}

.Cart .cartImg {
  width: 187px;
  height: 187px;
}

.Cart .itemName {
  font-size: 18px;
  font-weight: bold;
  color: var(--redColor);
  margin-bottom: 15px;
}

.Cart .itemDesc {
  font-size: 12px;
  font-weight: 400;
  color: var(--darkGreyColor);
  margin-bottom: 25px;
}

.Cart .off {
  font-size: 13px;
  font-weight: 300;
  color: var(--redColor);
  margin-right: 15px;
}

.Cart .old {
  font-size: 9px;
  font-weight: 500;
  color: var(--darkGreyColor);
  margin-right: 10px;
}

.Cart .sale {
  font-size: 17px;
  font-weight: bold;
  color: var(--blackColor);
}

.Cart .quantity {
  font-size: 18px;
  font-weight: 500;
  color: var(--darkGreyColor);
}

.Cart .quantityBox {
  width: 45px;
  height: 52px;
  border: 0;
  font-size: 18px;
  color: var(--middarkGreyColor);
}

.Cart .quantityBox:focus {
  outline: none;
}

.Cart .quantityBox::-webkit-inner-spin-button,
.Cart .quantityBox::-webkit-outer-spin-button {
  opacity: 1;
}

.Cart .deleteBtn {
  position: absolute;
  top: 25px;
  right: 23px;
  cursor: pointer;
  color: var(--darkGreyColor);
}

.Cart .price {
  font-size: 16px;
  color: var(--darkGreyColor);
}

.Cart .sideTitlep {
  font-size: 18px;
  color: var(--blackColor);
  margin-bottom: 20px;
}

.Cart .proceedBtn {
  width: 100%;
  background-color: var(--redColor);
  color: var(--whiteColor);
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 26px;
  height: 52px;
  line-height: 52px;
  margin-top: 20px;
  border: 0;
  text-align: center;
}

.Cart .continueBtn {
  width: 100%;
  background-color: var(--blackColor);
  color: var(--whiteColor);
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 26px;
  height: 52px;
  line-height: 52px;
  margin-top: 20px;
  text-align: center;
}

.Checkout{
  margin-top: 110px;
}

.Checkout h1 {
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
  margin-bottom: 76px;
}

.Checkout .fontOverride .infoTitle,
.Checkout .fontOverride .title {
  font-size: 17px !important;
}

.Checkout .CheckoutImg {
  width: 81px;
  height: 81px;
}

.Checkout .itemName {
  font-size: 14px;
  font-weight: bold;
  color: var(--redColor);
}

.Checkout .itemName span {
  color: var(--darkGreyColor);
}

.Checkout .itemPrice {
  font-size: 20px;
  font-weight: bold;
  color: var(--blackColor);
}

.Tracking h1 {
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
  margin-bottom: 76px;
}

.Tracking .orderNumber {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.Tracking .orderNumber a {
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  color: var(--redColor);
  text-decoration: underline;
  margin-left: 30px;
}

.Tracking .expected {
  font-size: 16px;
  color: var(--darkGreyColor);
}

.Tracking .infoBox {
  border: 1px solid var(--borderColor);
  padding: 55px 60px;
  margin-bottom: 215px;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  flex: 1;
}
@media (max-width: 768px) {
  .stepper-item {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-top: 5px solid var(--lightGreyColor);
  border-bottom: 5px solid var(--lightGreyColor);
  width: 100%;
  top: 20px;
  /* left: -50%; */
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 5px solid var(--lightGreyColor);
  border-top: 5px solid var(--lightGreyColor);
  width: 100%;
  top: 20px;
  /* left: 50%; */
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--lightGreyColor);
  margin-bottom: 6px;
  font-size: 20px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: var(--redColor);
  color: var(--whiteColor);
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-top: 5px solid var(--redColor);
  border-bottom: 5px solid var(--redColor);
  width: 100%;
  top: 15px;
  /* left: 50%; */
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.Tracking .infoTitle {
  font-size: 17px;
  font-weight: 500;
  color: var(--darkGreyColor);
}

.Tracking .orderInfo {
  font-family: 'Lato';
  font-size: 13px;
  font-weight: 400;
  color: var(--middarkGreyColor);
}

.Tracking .orderText {
  font-size: 12px;
  font-weight: 400;
  color: var(--midGreyColor);
}

.Tracking .orderPrice {
  font-size: 17px;
  font-weight: bold;
  color: var(--blackColor);
}


.Deals .off {
  font-size: 21px;
  font-weight: 300;
  color: var(--redColor);
  margin-right: 15px;
}

.Deals .old {
  font-size: 14px;
  font-weight: 500;
  color: var(--darkGreyColor);
  margin-right: 10px;
}

.Deals .sale {
  font-size: 17px;
  font-weight: bold;
  color: var(--blackColor);
}

.Deals .plus{
  position: absolute;
  right: 50%;
  top: 0;
  font-size: 16px;
  font-weight: bold;
  color: var(--redColor);
}

.Returns h1{
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
  margin-bottom: 76px;
}

.Returns .returnTitle{
  font-size: 35px;
  color: var(--blackColor);
}

.Returns .text{
  font-size: 18px;
  color: #707070;
  margin-top: 35px;
}

.Faqs h1{
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
  margin-bottom: 76px;
}

.Faqs .accordion-button:not(.collapsed){
  color: #0d0d0d!important;
  background-color: transparent!important;
}
.Faqs .accordion-button:focus{
  box-shadow: none!important;
}

.Faqs .accordion-item{
  border: 0!important;
}

.Faqs .accordion-header{
  border: 0!important;
  font-size: 16px;
}

.Faqs .qNumber{
  margin-right: 30px;
  font-weight: bold;
}

.Faqs .accordion-button:not(.collapsed)::after{
  /* width: 31px;
  height: 31px; */ 
  background-color: var(--whiteColor);
  color: var(--blackColor);
  border-radius: 50%;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%2300000' class='bi bi-dash' viewBox='0 0 16 16'><path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>")!important;
}

.Faqs .accordion-button::after{
  /* width: 31px;
  height: 31px; */
  background-color: var(--blackColor);
  color: var(--whiteColor);
  border-radius: 50%;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%23FFFFFF' class='bi bi-plus' viewBox='0 0 16 16'><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>")!important;
}

.Faqs .accordion-button:not(.collapsed){
  box-shadow: none!important;
  background-color: var(--blackColor)!important;
  color: var(--whiteColor)!important;
  border-radius: 26px!important;
}

.Faqs .accordion-body{
  padding-left: 75px;
}

.Cservice h1{
  font-size: 59px;
  font-weight: 700;
  color: var(--redColor);
  text-transform: uppercase;
  margin-bottom: 76px;
}

.Cservice .CsTitle{
  font-size: 35px;
  color: var(--blackColor);
  margin-bottom: 40px;
} 

.Cservice .text{
  font-size: 18px;
  color: var(--darkGreyColor);
} 

.Cservice .text svg{
  font-size: 20px;
  color: var(--redColor);
  margin-right: 20px;
}

.Cservice .send{
  font-size: 18px;
  font-weight: bold;
  color: var(--blackColor);
  margin-top: 40px;
}

.Cservice .inputBox {
  border: 0;
  height: 41px;
  border-radius: 21px;
  padding-left: 20px;
  font-size: 13px;
  font-family: "Lato";
  width: 100%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
}

.Cservice .inputBox::placeholder{
  font-size: 13px;
  color: var(--middarkGreyColor);
}

.Cservice .inputBox:focus  {
  border: 2px solid var(--redColor);
  outline: none;
}

.Cservice .infoTitle {
  font-size: 17px;
  font-weight: 500;
  color: var(--darkGreyColor);
  margin-bottom: 30px;
  line-height: 41px;
}

.Cservice .sendBtn{
  background-color: var(--redColor);
  color: var(--whiteColor);
  border-radius: 21px;
  border: 0;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  height: 41px;
}


.productsList .accordion-button:not(.collapsed),.productsList .accordion-button{
  background-color: white!important;
  border-radius: 21px!important;
  width: 100%!important;
  border: 0!important;
  color: var(--redColor)!important;
  font-size: 14px!important;
  height: 31px!important;
  box-shadow: 0px 3px 10px var(--midGreyColor)!important;
  margin-bottom: 21px!important;
}
/* .productsList .accordion-button:focus{
  box-shadow: none!important;
} */

.productsList .accordion-item{
  border: 0!important;
}

.productsList .accordion-header{
  border: 0!important;
  font-size: 16px;
}

.productsList .accordion-button:not(.collapsed)::after{
  display: none;
}

.productsList .accordion-button::after{
  display: none;
}

.productsList .accordion-button:not(.collapsed){
  background-color: var(--redColor)!important;
  border-radius: 21px!important;
  width: 100%!important;
  border: 0!important;
  color: var(--whiteColor)!important;
  font-size: 14px!important;
  height: 31px!important;
  box-shadow: 0px 3px 10px var(--midGreyColor)!important;
  margin-bottom: 21px!important;
}

 .topFilterLine{
  margin-bottom: 42px;
  border-bottom: 1px solid var(--midGreyColor);
  padding-bottom: 15px;
}

.topFilterLine .filterTitle{
  font-size: 17px;
  font-weight: bold;
  color: var(--redColor);
}

.topFilterLine .noProducts{
  font-size: 16px;
  color: var(--darkGreyColor);
  padding-right: 15px;
  border-right: 1px solid var(--midGreyColor);
  margin-right: 15px;
}

.topFilterLine .sortBy{
  font-size: 16px;
  color: var(--darkGreyColor);
}

.topFilterLine .sortBy select{
  border: 0;
  background-color: transparent;
  color: var(--darkGreyColor);
}

.topFilterLine .sortBy select:focus-visible{
  border: 0;
  outline: none;
}

.topFilterLine .listBy svg{
  margin-right: 20px;
  font-size: 20px;
  color: var(--darkGreyColor);
  cursor: pointer;
}


.plCheckbox{
	border-radius: 50%;
	height: 20px;
  	width: 20px;
	appearance: none;
	outline: 0;
	cursor: pointer;
	border: 1px solid #000;
	float: left;
}

.plCheckbox:checked{
	background-color: #000;
	border: 6px double var(--middarkGreyColor);
}
.pagination {
  display: flex;
  justify-content: center;
}
.pagination-pages {
  width: 45px;
  height: 45px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pagination-pages a {
  text-decoration: none;
  color: #000;
  font-size: 18px;
}
.active-page {
  background: var(--redColor);
}
.pagination-pages.active-page a {
  color: #fff;
}
.searchNewBox{
  border-radius: 7px !important;
}
.btn-check:focus+.btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgb(254 0 0/ 100%);
}
.dropdown-item:hover {
  background-color: red;
  color: white;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown a {
  cursor: pointer;
  padding-right: 20px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  border-bottom-right-radius: 10%;
  border-bottom-left-radius: 10%;
  
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: red;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

.suggested-content {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 360px;
  padding: 10px;
  z-index: 9999999;


}
.suggested-content .SuggestImg{
  width: 61px;
  height: 61px;
}
.suggested-content .suggested-content-Main{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.suggested-content .suggested-content-Main:hover{
  background-color:#9e9e9e;
}

.viewMore-Button{
  display: flex;
  justify-content: center;
}

.viewMore-Button h5 {
  cursor: pointer;
  color: red;
}
.mobileNewsLetterSection {
  margin-bottom: 100px;
}


.choose-selection-button {
  background: var(--redColor);
  border: 0 none;
  border-radius: 5px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  margin-bottom: 25px;
}

/* Error Page */

.Error {
  margin-top: 110px;
  margin-bottom: 165px;
}
.Error h1 {
  font-size: 59px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 76px;
}

.payment-option {
  display: flex;
  align-items: center;
}

.payment-radio {
  margin-right: 5px; /* Adjust the margin as needed */
}

.body-custom {
    background-color: #5165ff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-body-custom {
    background-color: #fff;
    border-color: #fff;
}

.close-custom {
    color: #000;
    cursor: pointer;
}

.close-custom:hover {
    color: #000;
}

.theme-color-custom {
    color: red;
}

hr.new1-custom {
    border-top: 2px dashed #fff;
    margin: 0.4rem 0;
}

.btn-primary-custom {
    color: #fff;
    background-color: #004cb9;
    border-color: #004cb9;
    padding: 12px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 1px;
    font-size: 17px;
}

.btn-primary-custom:hover {
    color: #fff;
    background-color: #004cb9;
    border-color: #004cb9;
    padding: 12px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 1px;
    font-size: 17px;
}
.out-of-stock-banner {
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.out-of-stock-overlay {
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.cartBtn.disabled {
  opacity: 0.5; /* Make button semi-transparent */
  pointer-events: none; /* Disable pointer events */
}

.wishList-Align-Text{
  text-align : center;
}

.lightcoral-bg {
  background-color: #ff0000b3;
}
.termsAndConditions{
  display: grid;
  align-items: center;
  height: 150px;
}
.underline {
  text-decoration: underline;
}
/* End Error Page */


/* color attribute custom css begin */
.color-attribute-outer {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* border: 1px solid; */
  background-color: rgba(255, 255, 255, 0.793);
  display: flex;
  align-items: center;
  justify-content: center;
}
.color-attribute-inner {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
}
/* color attribute custom css end */